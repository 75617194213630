import {
  BaseResponse,
  BrowseMenuContentResponse,
  CardResponse,
  ControlResponse,
  CurrentSong,
  CurrentSongResponse,
  Language,
  LastPlayedSongsResponse,
  Operation,
  Player,
  PlayerResponse,
  Pointer,
  Service,
  SingleCardResponse,
  SlugsResponse,
  View,
  ViewMeta,
  ViewResponse,
} from '@yleisradio/areena-types';
import { BrowseMenuContent } from '@yleisradio/areena-types/domain/v2/browseMenuContent';
import axios, { AxiosError, isAxiosError } from 'axios';
import NodeCache from 'node-cache';
import {
  FetchError,
  NotFoundError,
  TimeoutError,
} from 'services/areena-api/fetchErrors';
import { filterCardByAudience } from 'utils/content';
import { ErrorWithNotifications } from 'utils/errorWithNotifications';
import { isAreenaProgramID } from 'utils/item';
import { getUrlWithAuthentication } from '../common';
import logger from '../logger';
import { areenaApiProperties } from '../properties/frontend';
import { assertIsAreenaApiResponse } from './assertions';
import { isResponseHeaders } from './typeGuards';
import { ResponseHeaders } from './types';

const CONNECTION_TIMEOUT = 5000;
const RESPONSE_TIMEOUT = 5000;

const cache = new NodeCache({});

const cacheDuration = {
  appData: 60 * 15, // 15 min
  slugs: 60 * 10,
  browseMenuContent: 60 * 15,
};

const { appBase } = areenaApiProperties;

export function getCommonUiApiParameters(language: Language): string {
  return `language=${language}&client=yle-areena-web&v=10`;
}

function getErrorMessage({
  url,
  method,
  status,
  json,
}: {
  url: string;
  method: string;
  status?: number | null | undefined;
  json?: BaseResponse | null | undefined;
}): string {
  const notificationMessages = getDeveloperMessages(json);
  return `${method} request to Areena API URL ${url} ${
    status ? `resulted in HTTP ${status}` : 'failed'
  }${notificationMessages ? `. ${notificationMessages}` : ''}`;
}

/**
 * Fetch wrapper with automatic JSON parsing
 *
 * @throws {NotFoundError} When response status is 404.
 * @throws {FetchError} For all other non-okay responses.
 **/
async function areenaApiFetch<T extends BaseResponse>(
  url: string,
  init?: RequestInit
): Promise<{
  headers: Headers;
  json: T | null;
}> {
  const method = init?.method || 'GET';
  const { appId, appKey } = areenaApiProperties;
  const urlWithAuth = getUrlWithAuthentication(url, appId, appKey);

  try {
    const response = await fetch(urlWithAuth, {
      credentials: 'include',
      ...init,
    });

    let json: T | null = null;

    if (response.headers.get('Content-Type')?.startsWith('application/json')) {
      json = await response.json();
      assertIsAreenaApiResponse<T>(json);
    }

    if (response.ok) {
      return {
        headers: response.headers,
        json: json,
      };
    } else if (response.status === 404) {
      throw new NotFoundError(
        getErrorMessage({ url, method, status: response.status, json }),
        { response: json }
      );
    }

    throw new FetchError(
      getErrorMessage({ url, method, status: response.status, json }),
      {
        response: json,
      }
    );
  } catch (e) {
    if (e instanceof FetchError) {
      throw e;
    }

    throw new FetchError(`${getErrorMessage({ url, method })} - ${e}`, {
      originalError: e,
    });
  }
}

type ServerRequestOptions = {
  headers?: Record<string, string>;
  method?: string;
};

/**
 * Request wrapper with automatic JSON parsing to be used on server only
 *
 * @throws {NotFoundError} When response status is 404.
 * @throws {FetchError} For all other non-okay responses.
 * @throws {TimeoutError} When response has timed out.
 **/
async function areenaApiServerRequest<T extends BaseResponse>(
  url: string,
  { headers: reqHeaders = {}, method = 'GET' }: ServerRequestOptions
): Promise<{
  headers: ResponseHeaders | null;
  json: T | null;
}> {
  const { appId, appKey } = areenaApiProperties;
  const urlWithAuth = getUrlWithAuthentication(url, appId, appKey);

  try {
    const { data, headers: axiosHeaders } = await axios<unknown>(urlWithAuth, {
      headers: reqHeaders,
      method,
      signal: AbortSignal.timeout(CONNECTION_TIMEOUT),
      timeout: RESPONSE_TIMEOUT,
    });

    let json: T | null = null;

    if (data) {
      assertIsAreenaApiResponse<T>(data);
      json = data;
    }

    return {
      headers: isResponseHeaders(axiosHeaders) ? axiosHeaders : {},
      json: json,
    };
  } catch (e) {
    if (isAxiosError<BaseResponse>(e) && e.response) {
      const { status, data } = e.response;

      if (status === 404) {
        throw new NotFoundError(
          getErrorMessage({ url, method, status, json: data }),
          {
            originalError: e,
            response: data,
          }
        );
      }

      throw new FetchError(
        getErrorMessage({ url, method, status, json: data }),
        {
          originalError: e,
          response: data,
        }
      );
    }

    if (
      isAxiosError(e) &&
      (e.code === AxiosError.ECONNABORTED ||
        e.code === AxiosError.ERR_CANCELED ||
        e.code === AxiosError.ETIMEDOUT)
    ) {
      throw new TimeoutError(
        `${method} request to Areena API URL ${url} timed out`,
        { originalError: e }
      );
    }

    throw new FetchError(getErrorMessage({ url, method }), {
      originalError: e,
    });
  }
}

function getUrlWithParameters(
  url: string,
  parameters: Record<string, string>
): string {
  const urlObject = new URL(url);

  Object.entries(parameters).forEach(([key, value]) => {
    urlObject.searchParams.set(key, value);
  });

  return urlObject.toString();
}

function getDeveloperMessages(
  response: BaseResponse | null | undefined
): string {
  const notifications = response?.notifications || [];
  return notifications.reduce(
    (acc, { developerMessage }) =>
      developerMessage ? `${acc}${acc && ', '}"${developerMessage}"` : acc,
    ''
  );
}

export async function fetchBrowseMenuContentData(
  language: Language
): Promise<BrowseMenuContent> {
  const url = `${appBase}/v2/web/browseMenuContent?language=${language}`;

  const cachedResult = cache.get<BrowseMenuContent>(url);
  if (cachedResult) {
    logger.debug('Returning cached browse menu content result');
    return cachedResult;
  }

  logger.debug(`Fetching browse menu content data`);

  try {
    const response = await areenaApiFetch<BrowseMenuContentResponse>(url);

    if (response.json && response.json.data) {
      logger.debug(`Fetch for browse menu content data was successful`);
      const result = response.json.data;

      cache.set(url, result, cacheDuration.browseMenuContent);
      return result;
    }

    throw new Error(`browse menu content data response doesn't contain data`);
  } catch (e) {
    throw new Error(`browse menu content data fetch failed. ${e}`);
  }
}

export async function getChannelView(
  channelId: string,
  language: Language
): Promise<ViewResponse> {
  const url = `${appBase}/v1/ui/views/services/${channelId}/live.json?${getCommonUiApiParameters(
    language
  )}`;

  const response = await areenaApiFetch<ViewResponse>(url);

  if (!response.json) throw new Error('Empty response');

  return response.json;
}

export type ViewFetchReturn = {
  view: View;
  meta: ViewMeta;
  cacheControl: string | null;
  setCookieHeaders: string[] | undefined;
};

export async function getView({
  path,
  language,
  requestCookie,
}: {
  /** Areena API view path */
  path: string;
  language: Language;
  /** User's Cookie header value */
  requestCookie: string | undefined;
}): Promise<ViewFetchReturn> {
  logger.debug(`Fetching view for path ${path}`);

  const url = `${appBase}${path}?${getCommonUiApiParameters(language)}`;

  const response = await areenaApiServerRequest<ViewResponse>(
    url,
    requestCookie ? { headers: { Cookie: requestCookie } } : {}
  );

  if (response.json && response.json.data && response.json.meta) {
    logger.debug(`View fetch for path ${path} was successful`);

    return {
      view: response.json.data,
      meta: response.json.meta,
      cacheControl: response.headers?.['cache-control'] || null,
      setCookieHeaders: response.headers?.['set-cookie'],
    };
  } else {
    const messages = getDeveloperMessages(response.json);
    throw new Error(`View response doesn't contain required data. ${messages}`);
  }
}

export async function fetchPlaybackHistory(
  cardIds: string[],
  language: Language
): Promise<CardResponse | null> {
  if (cardIds.length === 0) return null;

  const url = `${appBase}/v1/ui/profile/history?filter.id=${cardIds}&${getCommonUiApiParameters(
    language
  )}`;

  const response = await areenaApiFetch<CardResponse>(url);

  return response.json;
}

export async function getCards(
  pointer: Pointer,
  offset: number,
  limit: number,
  isUserAuthenticated: boolean,
  locationParameters: Record<string, string>,
  filterParameters?: Record<string, string>
): Promise<CardResponse> {
  const url = getUrlWithParameters(pointer.uri, {
    offset: String(offset),
    limit: String(limit),
    ...filterParameters,
    ...locationParameters,
  });

  logger.debug(`Fetching cards with URL ${url}`);

  try {
    const response = await areenaApiFetch<CardResponse>(url);

    if (!response.json) {
      throw new Error(`Card fetch from "${pointer.uri}" returned no JSON`);
    }

    if (response.json.data) {
      response.json.data = response.json.data.map((card) =>
        filterCardByAudience(card, isUserAuthenticated)
      );
    }

    return response.json;
  } catch (e) {
    console.error(e, `Card fetch from "${pointer.uri}" failed ${e}`);
    return { data: [], meta: { count: 0, offset: 0, limit: 0 } };
  }
}

export async function getCard(
  pointer: Pointer,
  locationParameters: Record<string, string>
): Promise<SingleCardResponse | null> {
  const url = getUrlWithParameters(pointer.uri, locationParameters);

  logger.debug(`Fetching a card from URL ${url}`);

  try {
    const response = await areenaApiFetch<SingleCardResponse>(url);

    return response.json;
  } catch (e) {
    throw new Error(`Card fetch failed. ${e}`);
  }
}
export async function getCardById(
  itemId: string,
  language: Language
): Promise<SingleCardResponse | null> {
  const path = `/v1/ui/items/${itemId}.json`;
  const url = `${appBase}${path}?${getCommonUiApiParameters(language)}`;

  logger.debug(`Fetching a card from URL ${url}`);

  try {
    const response = await areenaApiFetch<SingleCardResponse>(url);

    return response.json;
  } catch (e) {
    console.error(e, `Card fetch failed. ${e}`);
    return null;
  }
}

export async function fetchQueueCard(
  itemId: string,
  language: Language
): Promise<SingleCardResponse | null> {
  const path = `/v1/ui/items/${itemId}/queued`;
  const url = `${appBase}${path}?${getCommonUiApiParameters(language)}`;

  logger.debug(`Fetching a card from URL ${url}`);

  try {
    const response = await areenaApiFetch<SingleCardResponse>(url);

    return response.json;
  } catch (e) {
    throw new Error(`Card fetch failed. ${e}`);
  }
}

export async function getControl(
  pointer: Pointer,
  locationParameters: Record<string, string>
): Promise<ControlResponse | null> {
  const url = getUrlWithParameters(pointer.uri, locationParameters);

  logger.debug(`Fetching Control from URL ${url}`);

  try {
    const response = await areenaApiFetch<ControlResponse>(url);

    return response.json;
  } catch (e) {
    throw new Error(`Control fetch failed. ${e}`);
  }
}

export async function getAvailablePlayerByProgramId(
  id: string,
  language: Language,
  locationParameters: Record<string, string>
): Promise<Player | null> {
  logger.debug(`Getting available player for program ${id}`);

  return await getAvailablePlayerByPointer(
    {
      type: 'player',
      uri: `${appBase}/v1/ui/players/${id}.json?${getCommonUiApiParameters(
        language
      )}`,
    },
    locationParameters
  );
}

/** Get currently available Player from player pointer */
export async function getAvailablePlayerByPointer(
  pointer: Pointer,
  locationParameters: Record<string, string>
): Promise<Player | null> {
  if (pointer.type !== 'player') {
    throw new Error(
      `Can't get available player from Pointer with type "${pointer.type}" (needs to be "player")`
    );
  }

  const url = getUrlWithParameters(pointer.uri, locationParameters);

  logger.debug(`Getting available player from URL ${url}`);

  try {
    const response = await areenaApiFetch<PlayerResponse>(url);

    return response.json?.data?.ondemand || response.json?.data?.live || null;
  } catch (e) {
    logger.error(e, 'Fetching of available player failed.');
    return null;
  }
}

export async function executeOperation(
  operation: Operation
): Promise<ControlResponse | null> {
  logger.debug(
    `Executing ${operation.method} operation for URL ${operation.uri}`
  );

  try {
    const response = await areenaApiFetch<ControlResponse>(operation.uri, {
      method: operation.method,
      headers: operation.headers as Record<string, string>,
      body: operation.body || null,
    });

    logger.debug('Operation executed successfully');

    return response.json;
  } catch (e) {
    if (e instanceof FetchError && e.notifications) {
      throw new ErrorWithNotifications('Operation failed', e.notifications, e);
    }

    throw new Error('Executing operation failed', { cause: e });
  }
}

export async function updateFavoriteVisitedTime(itemId: string): Promise<void> {
  if (!(await isItemFavorite(itemId))) return;

  const language: Language = 'fi';
  const url = `${appBase}/v1/ui/profile/favorites/${encodeURIComponent(itemId)}?${getCommonUiApiParameters(
    language
  )}`;

  try {
    await areenaApiFetch(url, {
      method: 'PUT',
    });

    logger.debug(
      `Favorite visited time updated successfully for item ${itemId}`
    );
  } catch (e) {
    throw new Error(`Favorite visited time update failed. ${e}`);
  }
}

async function isItemFavorite(itemId: string): Promise<boolean> {
  // All favorites are programs or series at the moment so don't check further if id is not program or series
  if (!isAreenaProgramID(itemId)) return false;

  const language: Language = 'fi';
  const url = `${appBase}/v1/ui/profile/favorites?filter.id=${encodeURIComponent(
    itemId
  )}&limit=0&${getCommonUiApiParameters(language)}`;

  const response = await areenaApiFetch<CardResponse>(url);

  const favoriteCount = response.json?.meta?.count || 0;
  return favoriteCount > 0;
}

export async function fetchLastPlayedSongs(
  serviceId: string,
  language: Language
): Promise<LastPlayedSongsResponse | null> {
  const uri = `${appBase}/v1/songs/last-played.json?serviceId=${encodeURIComponent(
    serviceId
  )}&${getCommonUiApiParameters(language)}`;

  try {
    const response = await areenaApiFetch<LastPlayedSongsResponse>(uri);

    return response.json;
  } catch (e) {
    throw new Error(`Fetching last played songs failed. ${e}`);
  }
}

export async function getCurrentSongForChannel(
  channelId: string,
  language: Language
): Promise<CurrentSong | null> {
  const uri = `${appBase}/v1/songs/current.json?serviceId=${encodeURIComponent(
    channelId
  )}&${getCommonUiApiParameters(language)}`;

  try {
    const response = await areenaApiFetch<CurrentSongResponse>(uri);

    return response.json?.data || null;
  } catch (e) {
    if (e instanceof NotFoundError) return null;

    throw new Error(`Fetching current song failed. ${e}`);
  }
}

export async function fetchRadioChannelCards(
  itemId: string,
  language: Language,
  limit = 10
): Promise<CardResponse | null> {
  const url = `${appBase}/v1/ui/schedules/${itemId}.json?limit=${limit}&${getCommonUiApiParameters(
    language
  )}`;

  try {
    const response = await areenaApiFetch<CardResponse>(url);
    return response.json;
  } catch (e) {
    throw new Error(`Fetching radio channel cards failed. ${e}`);
  }
}

export async function fetchSlugs(
  service: Service,
  language: Language
): Promise<SlugsResponse | null> {
  const url = `${appBase}/v1/ui/${
    service === 'tv' ? 'tv-app' : 'radio-app'
  }/slugs.json?${getCommonUiApiParameters(language)}`;
  const cachedResult = cache.get<SlugsResponse>(url);

  if (cachedResult) {
    logger.debug(`Returning cached slugs for service ${service}`);
    return cachedResult;
  }

  try {
    logger.debug(`Fetching slugs for service ${service}`);

    const response = await areenaApiFetch<SlugsResponse>(url);
    cache.set(url, response.json, cacheDuration.slugs);

    return response.json;
  } catch (e) {
    throw new Error(`Fetching slugs failed. ${e}`);
  }
}
