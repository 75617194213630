import { Language } from '@yleisradio/areena-types';
import { BrazeSdkType } from 'contexts/BrazeSdkContext/types';
import getConfig from 'next/config';
import { brazeSdkProperties } from './properties/frontend';
import logger from './logger';

export async function init(
  braze: BrazeSdkType,
  userId: string,
  token: string,
  refreshToken: () => void,
  language: Language
): Promise<void> {
  const { apiKey, endpoint } = brazeSdkProperties;
  const {
    publicRuntimeConfig: { versionNumber },
  } = getConfig();

  if (!apiKey) {
    throw new Error('No Braze SDK API key provided');
  }

  const success = braze.initialize(apiKey, {
    baseUrl: endpoint,
    doNotLoadFontAwesome: true,
    enableSdkAuthentication: true,
    localization: language,
    ...(versionNumber && { appVersion: versionNumber }),
  });

  if (!success) {
    throw new Error('Failed to initialize Braze SDK');
  }

  braze.subscribeToSdkAuthenticationFailures(({ errorCode, reason }) => {
    logger.debug('Braze SDK authentication failed', { errorCode, reason });
    refreshToken();
  });

  const existingUser = braze.getUser();

  if (!existingUser || existingUser.getUserId() !== userId) {
    braze.changeUser(userId, token);
  }

  // Get user again after potentially changing it
  braze.getUser()?.setLanguage(language);

  braze.openSession();
}

export function teardown(braze: BrazeSdkType): void {
  braze.wipeData();
  braze.destroy();
  window.location.reload();
}
